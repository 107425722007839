<template>
  <div class="corneaOrder">
    <div class="top">
       <div>产品类型</div>
       <el-select class="sr" v-model="value" placeholder="请选择">
         <el-option
             v-for="item in cplx"
             :key="item.value"
             :label="item.label"
             :value="item.value">
         </el-option>
       </el-select>
       <div >产品名称</div>
       <div>
         <div class="yj">
           <p>OD</p>
           <el-select class="srs" v-model="value1" placeholder="请选择">
             <el-option
                 v-for="item in cpmc"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </div>
         <div class="yj">
           <p>OS</p>
           <el-select class="srs" v-model="value2" placeholder="请选择">
             <el-option
                 v-for="item in cpmc"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </div>
       </div>
     </div>

    <div class="cf">
      <h3>处方</h3>
      <div class="srA">
        <div>
          <ul class="content-sr padding-20">
            <li></li>
            <li>DS</li>
            <li>DC</li>
            <li>X</li>
            <li>FK</li>
            <li>SK</li>
            <li>VA</li>
            <li>PC</li>
            <li>BC</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input @change="zh('CL_Order_OD_DS', 1)" v-model="postData.S_OP_Json.CL_Order_OD_DS" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_DC', 1)" v-model="postData.S_OP_Json.CL_Order_OD_DC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_X')" v-model="postData.S_OP_Json.CL_Order_OD_X" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_FK')" v-model="postData.S_OP_Json.CL_Order_OD_FK" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_SK')" v-model="postData.S_OP_Json.CL_Order_OD_SK" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_VA')" v-model="postData.S_OP_Json.CL_Order_OD_VA" placeholder="请输入内容"></el-input></li>

            <li><el-input @change="zh('CL_Order_OD_VA')" v-model="postData.S_OP_Json.CL_Order_OD_PC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_VA')" v-model="postData.S_OP_Json.CL_Order_OD_BC" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr padding-20">
            <li>OS</li>
            <li><el-input @change="zh('CL_Order_OS_DS', 1)" v-model="postData.S_OP_Json.CL_Order_OS_DS" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_DC', 1)" v-model="postData.S_OP_Json.CL_Order_OS_DC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_X')" v-model="postData.S_OP_Json.CL_Order_OS_X" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_FK')" v-model="postData.S_OP_Json.CL_Order_OS_FK" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_SK')" v-model="postData.S_OP_Json.CL_Order_OS_SK" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_VA')" v-model="postData.S_OP_Json.CL_Order_OS_VA" placeholder="请输入内容"></el-input></li>

            <li><el-input @change="zh('CL_Order_OD_VA')" v-model="postData.S_OP_Json.CL_Order_OS_PC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_VA')" v-model="postData.S_OP_Json.CL_Order_OS_BC" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
        <el-button @click="tb" class="last" type="primary">同步数据</el-button>
      </div>
    </div>

    <div class="cf" v-if="value == '0'">
      <h3>参数</h3>
      <div class="srA">
        <div>
          <ul class="content-sr padding-20">
            <li></li>
            <li>AC</li>
            <li>PR</li>
            <li>DIA</li>
            <li>CP</li>
            <li>BC</li>
            <li>PC</li>
            <li>备注</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input @change="zh('CL_Order_OD_AC')" v-model="postData.S_OP_Json.CL_Order_OD_AC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_RP')" v-model="postData.S_OP_Json.CL_Order_OD_RP" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_DIA')" v-model="postData.S_OP_Json.CL_Order_OD_DIA" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_CP')" v-model="postData.S_OP_Json.CL_Order_OD_CP" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_BC')" v-model="postData.S_OP_Json.CL_Order_OD_BC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_PC')" v-model="postData.S_OP_Json.CL_Order_OD_PC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_remark')" v-model="postData.S_OP_Json.CL_Order_OD_remark" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr padding-20">
            <li>OS</li>
            <li><el-input @change="zh('CL_Order_OS_AC')" v-model="postData.S_OP_Json.CL_Order_OS_AC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_RP')" v-model="postData.S_OP_Json.CL_Order_OS_RP" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_DIA')" v-model="postData.S_OP_Json.CL_Order_OS_DIA" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_CP')" v-model="postData.S_OP_Json.CL_Order_OS_CP" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_BC')" v-model="postData.S_OP_Json.CL_Order_OS_BC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_PC')" v-model="postData.S_OP_Json.CL_Order_OS_PC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_remark')" v-model="postData.S_OP_Json.CL_Order_OS_remark" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="cf" v-if="value == '1'">
      <h3>参数</h3>
      <div class="srA">
        <div>
          <ul class="content-sr padding-20">
            <li></li>
            <li>编号</li>
            <li>BC</li>
            <li>RZD1</li>
            <li>RZD2</li>
            <li>LZA1</li>
            <li>LZA2</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input @change="zh('CL_Order_OD_Power_CRT')" v-model="postData.S_OP_Json.CL_Order_OD_Power_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_BC_CRT')" v-model="postData.S_OP_Json.CL_Order_OD_BC_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_RZD1_CRT')" v-model="postData.S_OP_Json.CL_Order_OD_RZD1_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_RZD2_CRT')" v-model="postData.S_OP_Json.CL_Order_OD_RZD2_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_LZA1_CRT')" v-model="postData.S_OP_Json.CL_Order_OD_LZA1_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_LZA2_CRT')" v-model="postData.S_OP_Json.CL_Order_OD_LZA2_CRT" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr padding-20">
            <li>OS</li>
            <li><el-input @change="zh('CL_Order_OS_Power_CRT')" v-model="postData.S_OP_Json.CL_Order_OS_Power_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_BC_CRT')" v-model="postData.S_OP_Json.CL_Order_OS_BC_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_RZD1_CRT')" v-model="postData.S_OP_Json.CL_Order_OS_RZD1_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_RZD2_CRT')" v-model="postData.S_OP_Json.CL_Order_OS_RZD2_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_LZA1_CRT')" v-model="postData.S_OP_Json.CL_Order_OS_LZA1_CRT" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_LZA2_CRT')" v-model="postData.S_OP_Json.CL_Order_OS_LZA2_CRT" placeholder="请输入内容"></el-input></li>
          </ul>

          <div>
            <ul class="content-sr padding-20">
              <li></li>
              <li>power</li>
              <li>DIA</li>
              <li>备注</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li><el-input @change="zh('CL_Order_OD_power_CRT')" v-model="postData.S_OP_Json.CL_Order_OD_power_CRT" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OD_DIA_CRT')" v-model="postData.S_OP_Json.CL_Order_OD_DIA_CRT" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OD_Remarks')" v-model="postData.S_OP_Json.CL_Order_OD_Remarks" placeholder="请输入内容"></el-input></li>
            </ul>
            <ul class="content-sr padding-20">
              <li>OS</li>
              <li><el-input @change="zh('CL_Order_OS_power_CRT')" v-model="postData.S_OP_Json.CL_Order_OS_power_CRT" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OS_DIA_CRT')" v-model="postData.S_OP_Json.CL_Order_OS_DIA_CRT" placeholder="请输入内容"></el-input></li>
              <li><el-input @change="zh('CL_Order_OS_Remarks')" v-model="postData.S_OP_Json.CL_Order_OS_Remarks" placeholder="请输入内容"></el-input></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="cf" v-if="value == '2'">
      <h3>参数</h3>
      <div class="srA">
        <div>
          <ul class="content-sr padding-20">
            <li></li>
            <li>光度</li>
            <li>BC</li>
            <li>DIA</li>
            <li>PC</li>
            <li>备注</li>
          </ul>
          <ul class="content-sr">
            <li>OD</li>
            <li><el-input @change="zh('CL_Order_OD_light_RGP')" v-model="postData.S_OP_Json.CL_Order_OD_light_RGP" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_BC')" v-model="postData.S_OP_Json.CL_Order_OD_BC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_DIA_RGP')" v-model="postData.S_OP_Json.CL_Order_OD_DIA_RGP" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_PC')" v-model="postData.S_OP_Json.CL_Order_OD_PC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OD_remark_RGP')" v-model="postData.S_OP_Json.CL_Order_OD_remark_RGP" placeholder="请输入内容"></el-input></li>
          </ul>
          <ul class="content-sr padding-20">
            <li>OS</li>
            <li><el-input @change="zh('CL_Order_OS_light_RGP')" v-model="postData.S_OP_Json.CL_Order_OS_light_RGP" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_BC')" v-model="postData.S_OP_Json.CL_Order_OS_BC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_DIA_RGP')" v-model="postData.S_OP_Json.CL_Order_OS_DIA_RGP" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_PC')" v-model="postData.S_OP_Json.CL_Order_OS_PC" placeholder="请输入内容"></el-input></li>
            <li><el-input @change="zh('CL_Order_OS_remark_RGP')" v-model="postData.S_OP_Json.CL_Order_OS_remark_RGP" placeholder="请输入内容"></el-input></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="time">
      <p>定片时间</p>
      <el-date-picker
          class="rq mag-7"
          v-model="postData.S_OP_Json.CL_Order_OrderDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
      <p>取镜时间</p>
      <el-date-picker
          class="rq"
          v-model="postData.S_OP_Json.CL_Order_TakeDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
    </div>

    <div class="bottom">
      <el-button @click="$router.back(-1)">返回</el-button>
      <el-button @click="save" type="primary">保存</el-button>
      <el-button @click="ysqm" type="primary">医生签名</el-button>
    </div>
  </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
import axios from "@/api/axios"
import { Loading } from 'element-ui';
export default {
  name: "corneaOrder",
  data() {
    return {
      value: '',
      value1: '',
      value2: '',
      cplx: [
        {
          value: '0',
          label: 'VST'
        },
        {
          value: '1',
          label: 'CRT'
        },
        {
          value: '2',
          label: 'RGP'
        },
        {
          value: '3',
          label: 'Misight'
        }
      ],
      cpmc:[
      ],
      cpmcdata: [],
      postData: {
        S_OP_ChooseId: "",
        S_OP_CustomerId: "",
        S_OP_ExpertId: "00000000-0000-0000-0000-000000000000",
        S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json: {
          CL_Order_Brand: "",
          CL_Order_ChooseId: "",
          CL_Order_OD_AC: "",
          CL_Order_OD_BC_CRT: "",
          CL_Order_OD_BC_RGP: "",
          CL_Order_OD_CP: "",
          CL_Order_OD_DC: "",
          CL_Order_OD_DIA: "",
          CL_Order_OD_DIA_CRT: "",
          CL_Order_OD_DIA_RGP: "",
          CL_Order_OD_DS: "",
          CL_Order_OD_FK: "",
          CL_Order_OD_LZA1_CRT: "",
          CL_Order_OD_LZA2_CRT: "",
          CL_Order_OD_Power_CRT: "",
          CL_Order_OD_RP: "",
          CL_Order_OD_RZD1_CRT: "",
          CL_Order_OD_RZD2_CRT: "",
          CL_Order_OD_Remarks: "",
          CL_Order_OD_SK: "",
          CL_Order_OD_VA: "",
          CL_Order_OD_X: "",
          CL_Order_OD_light_RGP: "",
          CL_Order_OD_power_CRT: "",
          CL_Order_OD_remark: "",
          CL_Order_OD_remark_RGP: "",
          CL_Order_OS_AC: "",
          CL_Order_OS_BC_CRT: "",
          CL_Order_OS_BC_RGP: "",
          CL_Order_OS_CP: "",
          CL_Order_OS_DC: "",
          CL_Order_OS_DIA: "",
          CL_Order_OS_DIA_CRT: "",
          CL_Order_OS_DIA_RGP: "",
          CL_Order_OS_DS: "",
          CL_Order_OS_FK: "",
          CL_Order_OS_LZA1_CRT: "",
          CL_Order_OS_LZA2_CRT: "",
          CL_Order_OS_Power_CRT: "",
          CL_Order_OS_RP: "",
          CL_Order_OS_RZD1_CRT: "",
          CL_Order_OS_RZD2_CRT: "",
          CL_Order_OS_Remarks: "",
          CL_Order_OS_SK: "",
          CL_Order_OS_VA: "",
          CL_Order_OS_X: "",
          CL_Order_OS_light_RGP: "",
          CL_Order_OS_power_CRT: "",
          CL_Order_OS_remark: "",
          CL_Order_OS_remark_RGP: "",
          CL_Order_OrderDate: "",
          CL_Order_Series: "",
          CL_Order_TakeDate: "",
          CL_Order_Type: "",
          CL_Order_amount: "",
          CL_Order_price: "",
          CL_Order_OD_PC: '',
          CL_Order_OD_BC: '',
          CL_Order_OS_PC: '',
          CL_Order_OS_BC: '',
        },
        S_OP_Model: "CL_Order180626",
        S_OP_Time: "",
        S_OP_Type: "CL_Order",
        S_OP_XML: ""
      }
    }
  },
  created() {
    this._api.corneaContact.getCorneaOrder()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length > 0) {

        this.postData = res.GetListResult[0]

        // console.log(this.postData)
        this.getChooseList(this.postData.S_OP_ID)
        this.postData.S_OP_Json = pf.decoding(this.postData.S_OP_Json)
        if (this.postData.S_OP_Json.CL_Order_Type) {
          switch (this.postData.S_OP_Json.CL_Order_Type) {
            case 'VST':
              this.value = '0'
                  break
            case 'CRT':
              this.value = '1'
              break
            case 'RGP':
              this.value = '2'
              break
            case 'Misight':
              this.value = '3'
              break
          }
        }
      }else {
        this.postData.S_OP_ChooseId = this.$store.state.physicianVisits.xzConsulting
        this.postData.S_OP_Json.CL_Order_ChooseId = this.$store.state.physicianVisits.xzConsulting
        this.postData.S_OP_CustomerId = this.$store.state.users.CsUser.CustomerId
      }
    })


    this.cshTime('contact_product')

    this.getList()
    // this._api.corneaContact.getCorneaShop()
    // .then(res => {
    //   console.log(res)
    // }),

  },
  methods: {
    save() {

      if ( this.$store.state.Time.timeObj.contact_product.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.contact_product.ENTER)/1000 + Number(this.$store.state.Time.timeObj.contact_product.EXIT)
        this._api.publicApi.addTime('contact_product,contact_prescriptionData,contact_params,contact_date', times)
        this.jsTime('contact_product', 1)
      }

      let type = ''
      if (this.value) type = this.cplx[Number(this.value)].label
      this.postData.S_OP_Json.CL_Order_Type = type
      let datas = JSON.parse(JSON.stringify(this.postData))
      datas.S_OP_Json = pf.conversionJson(datas.S_OP_Json)
      let lig = Loading.service({
        text: '保存中...',
        background: 'rgba(0, 0, 0, 0.8'
      })
      axios.post(pf.getUrl('S_ALL_Normal_Save'), {
        "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: datas
      })
      .then(res => {
        // console.log(res)
        lig.close()
        if (res.UpdateResult) {
          this.$alert('保存成功!', '提示')
          if (this.value1) this.add(1, res.S_OP_ID)
          if (this.value2) this.add(2, res.S_OP_ID)
        }else {
          this.$alert('保存失败!', '提示')
        }
      })
    },
    tb() {
      let datas = this.$store.state.eyeTest.refractiveData.S_OP_Json
      this.$set(this.postData.S_OP_Json, 'CL_Order_OD_DS', datas.S_OP_R_SS_DSOD)
      this.$set(this.postData.S_OP_Json, 'CL_Order_OD_DC', datas.S_OP_R_SS_DCOD)
      this.$set(this.postData.S_OP_Json, 'CL_Order_OD_X', datas.S_OP_R_SS_XOD)

      this.$set(this.postData.S_OP_Json, 'CL_Order_OS_DS', datas.S_OP_R_SS_DSOS)
      this.$set(this.postData.S_OP_Json, 'CL_Order_OS_DC', datas.S_OP_R_SS_DCOS)
      this.$set(this.postData.S_OP_Json, 'CL_Order_OS_X', datas.S_OP_R_SS_XOS)
    },

    zh(key, type=0) {
      let str = this.postData.S_OP_Json[key]
      if (str !== '' && Number(str) > 0) {
        if (type) {
          if (this.postData.S_OP_Json[key].indexOf("+") < 0) {
            this.postData.S_OP_Json[key] = "+" + str
          }
        }else {
          this.postData.S_OP_Json[key] = str.toString()
        }
      }
    },

    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    },

    ysqm() {
      this._api.expertClinic.ysqm('CLOrderDoctorSign')
          .then(res => {
            if (typeof res === 'object') {
              if (res.InsertResult) {
                this.$store.commit('physicianVisits/upIsqm', false)
                this.$alert('提交签名成功', '提示')
              }else {
                this.$alert('提交签名失败', '提示')
              }
            }
          })
    },

    getList() {
      this._http.post(this._getUrl('priceSortList'), {
        // SearchString: `${jk}:${str}`,
        "SearchCondition": {
          "ShopId": this.$store.state.bases.shop.ShopId,//实际ShopId
          "IndexMin": 0,
          "IndexMax":0,
          "SortKey": 'rgp',//镜片：lens；镜框：frame
          // "SortKey": 'exam',//镜片：lens；镜框：frame
          // "ProductName": this.cpmc,
          // "BrandId": this.ppid,//若有筛选品牌则填入该行，多个BrandId用英文逗号拼接
        },
        "ReturnInfo": {
          "Product": {
            "Brand": 1
          }
        },
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        }
      })
          .then(res => {
            if (res.GetRelationListResult) {
              // console.log(res.GetRelationListResult)
              this.cpmcdata= [...res.GetRelationListResult]
              let arr = []
              for (let i = 0; i < res.GetRelationListResult.length; i++) {
                arr.push({
                  value: res.GetRelationListResult[i].ProductDetail.Product.ProductId,
                  label: res.GetRelationListResult[i].ProductDetail.Product.ProductName
                })
              }
              arr.push({
                value: '',
                label: ''
              })
              this.cpmc = [...arr]
            }
          })
    },

    add(val, id) {
      let pic = 0
      for (let i = 0; i < this.cpmcdata.length; i++) {
        if (val == 1) {
          if (this.value1 == this.cpmcdata[i].ProductDetail.Product.ProductId) {
            pic = this.cpmcdata[i].ProductDetail.Product.ProductOfficalPrice
          }
        }
        if (val == 2) {
          if (this.value2 == this.cpmcdata[i].ProductDetail.Product.ProductId) {
            pic = this.cpmcdata[i].ProductDetail.Product.ProductOfficalPrice
          }
        }
      }
      this._http.post(this._getUrl('frameDetail_addToCart'), {
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        },
        "o": {
          "CHPChooseId": this.$store.state.physicianVisits.xzConsulting,//实际ChooseId
          "CHPProductId": val==1?this.value1:this.value2,//实际ProductId
          "CHPAmount":1,//最终实际数量，如果之前已加入1件，后来再增加到3件，则此处传3
          "CHPS_OP_ALL_Id": id,//绑定的处方的S_OP_ALLId，添加时此处可以先填写全0的Guid，后续再修改，镜片类商品需带有处方的S_OP_ALLId才可以下单
          "CHPLeftRightEyes": val==1?'OD':'OS',//左右眼或双眼，取值右眼：“OD”\左眼“OS”\双眼“OU”。添加时此处可以先填写空字符串，后续再修改，镜片类商品需带有左右眼信息可以下单
          "CHPProductSortGroup": "rgp",//等同于Product.ProductSortKey
          "CHPPrescriptionData":"",//药品类该项需要填入这个结构的字符串，其它类别填空字符串
          "CHPPrice": pic,//价格，暂不清楚是单价还是总价，取ExpertProduct.EPPrice或ShopProduct.SPPrice或Product.ProductOfficalPrice
        },
      })
          .then(res => {
            if (res.InsertResult) {
              // this.$alert('加入产品成功！', '提示')
            }else {
              this.$alert('加入产品失败！', '提示')
            }
          })
    },


    getChooseList(id) {
      this._api.expertClinic.getChooseList(id, 'rgp')
          .then(res => {
            console.log(res)
            if (res.GetListResult && res.GetListResult.length) {
              for (let i = 0; i < res.GetListResult.length; i++) {
                if (res.GetListResult[i].CHPLeftRightEyes === 'OD') {
                  this.value1= res.GetListResult[i].CHPProductId
                }
                if (res.GetListResult[i].CHPLeftRightEyes === 'OS') {
                  this.value2 = res.GetListResult[i].CHPProductId
                }
              }
            }
          })
    }
  },
  beforeDestroy() {
    if (this.$store.state.Time.timeObj.contact_product.ENTER != 0) {
      this.jsTime('contact_product')
    }
  }
}
</script>

<style scoped lang="scss">
  .corneaOrder {
    width: 100vw;
    height: 87vh;
    overflow-y: auto;
  }
  .top {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 .8rem;
    margin-top: .5rem;
    padding-bottom: .3rem;
    .yj {
      display: flex;
      justify-content: start;
      align-items: center;
      margin: .2rem 0;
      p {padding-right: 5px; width: 2em;}
    }
    .sr {
      margin-left: 5px;
      width: 10vw;
      margin-right: 6vw;
    }
    .srs {width: 16vw}
    border-bottom: 1px solid #40a7f6;
  }

  .mar-20 {margin-top: 30px}
  .padding-20{padding-top: 15px;}
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: .4rem;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 3vw;
        text-align: left;
        margin-right: 5px;
      }
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-left: .5rem;
      margin-top: 32px;
    }
  }
  .cf {
    padding-left: .8rem;
    padding-bottom: .3rem;
    h3 {text-align: left; padding-top: .5rem}
    border-bottom: 1px solid #40a7f6;
  }

  .time {
    padding: .3rem 0;
    display: flex;
    align-items: center;
    padding-left: .8rem;
    .rq {
      width: 13vw;
      margin-left: 5px;
    }
    .mag-7 {margin-right: .7rem}
  }

  .bottom {
    padding-top: .3rem;
    padding-bottom: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
